import {Helmet} from "react-helmet";
import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  FormGroup,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { AuthContext } from "context/userContext";
import toast from 'react-hot-toast';
import axios from "axios";
import AppConfig from "constants/config";
import Layout from "components/VerticalLayout";

const UserProfile = () => {
	const { userData, reloadUserData } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: '',
      email: '',
      password: '',
      confirmPass: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your name"),
      email: Yup.string().required("Please Enter Your email"),
    }),
    onSubmit: async(values) => {
      setLoading(true);
      try {
        if(values.password && !values.confirmPass){
          return formik.setFieldError('confirmPass', "Please enter confirm password")
        }
        if(values.password && values.confirmPass && values.password !== values.confirmPass){
          return formik.setFieldError('confirmPass', "Password and confirm password should be same")
        }
        values.id = userData.id;
        values.is_2fa_active = userData.is_2fa_active;
        const {data} = await axios.post(`${AppConfig.baseUrl}/super_admin/edit_profile`, values, {
					headers: { token: localStorage.getItem("token") }
				});
        if(!data.error){
          toast.success('Account updated successfully');
          reloadUserData()
        }
        else{
            throw new Error(data.title);
        }
      } catch (error) {
        toast.error(error.message || 'Something went wrong');
      }
      setLoading(false);
    }
  });

  useEffect(() =>{
    formik.setValues({
      name: userData.name,
      email: userData.email,
      password: '',
      confirmPass: '',
    })
  }, [userData])


  return (
    <Layout>
      <div className="page-content">
       <Helmet>
          <title>LeaseVista | Profile</title>
       </Helmet>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="LeaseVista" breadcrumbItem="EDIT PROFILE" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <h5 className="card-title mb-4"><i className="mdi mdi-arrow-right txt-theme-color arrow-spacing"></i>Basic details</h5>
                  <Row>
                    <Col lg="6">
                      <FormGroup className="mb-3">
                        <Label className="form-label mt-3">Name</Label>
                        <Input
                          name="name"
                          placeholder="Enter name"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name || ""}
                          invalid={
                            formik.touched.name && formik.errors.name ? true : false
                          }
                        />
                        {formik.touched.name && formik.errors.name && (
                          <FormFeedback type="invalid">{formik.errors.name}</FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup className="mb-3">
                        <Label className="form-label mt-3">Email</Label>
                        <Input
                          name="email"
                          placeholder="Enter Email"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled
                          value={formik.values.email || ""}
                          invalid={
                            formik.touched.email && formik.errors.email ? true : false
                          }
                        />
                        {formik.touched.email && formik.errors.email && (
                          <FormFeedback type="invalid">{formik.errors.email}</FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <h5 className="card-title mt-5 mb-4"><i className="mdi mdi-arrow-right txt-theme-color arrow-spacing"></i>Change password</h5>
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                        return false;
                      }}
                    >
                      <Row>
                        <Col lg="6">
                          <FormGroup className="form-group">
                            <Label className="form-label">New password</Label>
                            <Input
                              name="password"
                              className="form-control"
                              placeholder="Enter New password"
                              type="password"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.password || ""}
                              invalid={
                                formik.touched.password && formik.errors.password ? true : false
                              }
                            />
                            {formik.touched.password && formik.errors.password && (
                              <FormFeedback type="invalid">{formik.errors.password}</FormFeedback>
                            )}
                            <Input name="idx" type="hidden" />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="form-group">
                            <Label className="form-label">Confirm new password</Label>
                            <Input
                              name="confirmPass"
                              className="form-control"
                              placeholder="Confirm New password"
                              type="password"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.confirmPass || ""}
                              invalid={
                                formik.touched.confirmPass && formik.errors.confirmPass ? true : false
                              }
                            />
                            {formik.touched.confirmPass && formik.errors.confirmPass && (
                              <FormFeedback type="invalid">{formik.errors.confirmPass}</FormFeedback>
                            )}
                            <Input name="idx" type="hidden" />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <div className="text-end editprof-save-btn mt-4">
                          <Button type="submit" className="bg-theme-color" disabled={loading}>
                            {
                                loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                            }
                            SAVE
                          </Button>
                        </div>
                      </Row>
                    </Form>
                    </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
      );
    };
    
    export default UserProfile;
