import {Helmet} from "react-helmet";
import React, {useContext, useState} from "react";
import { Row, Col, CardBody, Card, Container, Form, Input, Label } from "reactstrap";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import AppConfig from "../../constants/config";
import * as Yup from "yup";
import { useFormik } from "formik";
import toast from 'react-hot-toast';
import { AuthContext } from "context/userContext";

const Login = () => {
	const { reloadUserData } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async(values) => {
    setLoading(true);
    try {
        const {data} = await axios.post(`${AppConfig.baseUrl}/super_admin/login`, values);
        if(!data.error){
          formik.resetForm();
          if(data.is_2fa_active){
            await localStorage.setItem('temp_token', data.token)
            navigate("/factorauth", { state: { key: "login" } });
          }
          else{
            await localStorage.setItem('token', data.token);
            reloadUserData()
            toast.success('Logged in Successfully');
            window.location.href = "/dashboard";
          }
        }
        else{
            throw new Error(data.title);
        }
    } catch (error) {
      toast.error(error.message == 'Network Error' ? 'Maintenance in process' : error.message);
    }
    setLoading(false);
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Please Enter Valid Email").required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: handleSubmit
  });

  return (
    <React.Fragment>
     <Helmet>
        <title>LeaseVista | Login</title>
     </Helmet>
      <div className="account-pages my-5 pt-sm-5 login-mt">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className=" bg-softbg-soft-primary bg-theme-color">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-white login-head">Welcome To LeaseVista Super Admin Panel</h5>
                        <p className="text-white">Sign in to continue</p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2 mt-4">
                    <Form
                      className="form-horizontal"
                      onSubmit={formik.handleSubmit}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                        {formik.touched.email && formik.errors.email &&
                          <div className="text-danger">{formik.errors.email}</div>
                        }
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          value={formik.values.password || ""}
                          type="password"
                          placeholder="Enter Password"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.password && formik.errors.password &&
                          <div className="text-danger">{formik.errors.password}</div>
                        }
                      </div>

                      {/* <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div> */}

                      <div className="mt-3 d-grid">
                        <button
                          className="btn bg-theme-color btn-block"
                          type="submit"
                          disabled={loading}
                        >
                          {
                              loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                          }
                          LOGIN
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login
