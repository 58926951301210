import React, { useEffect, useState } from "react"
import {Helmet} from "react-helmet";
import { map } from "lodash"
import { useLocation, useNavigate, Link } from "react-router-dom"
import { Card, CardBody, CardTitle, Col, Container, Row, Spinner, Badge, Button } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import MiniCards from "../../components/Common/MiniCard"
import BarChart from "../../components/Common/BarChart"
import toast from 'react-hot-toast';
import axios from "axios";
import AppConfig from "constants/config";
import moment from "moment";
import { capitalize, commaSeperatedNumberWithoutDecimal } from "helpers/stringHelper";
import Users from "pages/Users";
import Layout from "components/VerticalLayout";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getMuiTheme } from 'helpers/mui_theme'

const AccountDetails = () => {
    const navigate = useNavigate();
    const location = useLocation ();
    const [id, setId] = useState('');
    const [accountData, setAccountData] = useState({});
    const [clientData, setClientData] = useState([]);
    const [usersData, setUsersData] = useState([]);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [loadingClients, setLoadingClients] = useState(false);
    const [loading, setLoading] = useState(false);
    const [revenue, setRevneue] = useState();
    const [leaseByClient, setLeaseByClient] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [miniCards, setMiniCards] = useState([
        { title: "Total users", iconClass: "bx-user-circle", text: "0" },
        { title: "Total Clients", iconClass: "bxs-briefcase", text: "0" },
        { title: "Total revenue", iconClass: "bx-dollar", text: "$0" },
    ])
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    const [datasets, setDataset] = useState([{amt:0, month: "Jan"}, {amt:0, month: "Feb"}, {amt:0, month: "Mar"}, {amt:0, month: "Apr"}, {amt:0, month: "May"}, {amt:0, month: "Jun"}, {amt:0, month: "Jul"}, {amt:0, month: "Aug"}, {amt:0, month: "Sep"}, {amt:0, month: "Oct"}, {amt:0, month: "Nov"}, {amt:0, month: "Dec"}])

    const columns = [
        {
            name: "client",
            label: "Client",
            // eslint-disable-next-line react/display-name
            options : {
                customBodyRenderLite: (dataIndex) => (
                    <>
                        {clientData && clientData[dataIndex] && !clientData[dataIndex].img ? (
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div className="avatar-sm">
                                    <div className="avatar-title rounded-circle">
                                        {clientData && clientData[dataIndex] && clientData[dataIndex].client.charAt(0)}
                                    </div>
                                </div>
                                <div className="client">
                                    <div>{clientData && clientData[dataIndex] && clientData[dataIndex].client}</div>
                                </div>
                            </div>
                        ) : (
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div className="avatar-sm">
                                        <img
                                            className="rounded-circle avatar-sm"
                                            src={clientData && clientData[dataIndex] && clientData[dataIndex].logo}
                                            alt=""
                                        />
                                    </div>
                                    <div className="client">
                                        <div>{clientData && clientData[dataIndex] && clientData[dataIndex].client}</div>
                                    </div>
                                </div>
                            )}
                    </>
                )
            }
        },
        {
            name: "no_of_leases",
            label: "No Of Leases",
            options : {
                customBodyRenderLite: (dataIndex) => {
                    const clientLeases = leaseByClient && leaseByClient[0] ? leaseByClient.filter(v => String(v._id) == String(clientData && clientData[dataIndex] && clientData[dataIndex]._id)) : []
                    const no_of_leases = clientLeases && clientLeases [0] ? Number(clientLeases[0].activeLeases || 0) + ((Number(clientLeases[0].terminatedLeases || 0) + Number(clientLeases[0].expiredLeases || 0) + Number(clientLeases[0].draftedLeases || 0))/4) : 0
                    return <div 
                    className="table-data-margin"
                    tabIndex="0" dataToggle="tooltip" 
                    title={`Active Leases: ${clientLeases && clientLeases [0] ? Number(clientLeases[0].activeLeases || 0) : 0} \nTerminated Leases: ${clientLeases && clientLeases [0] ? Number(clientLeases[0].terminatedLeases || 0) : 0} \nInactive Leases: ${clientLeases && clientLeases [0] ? Number(clientLeases[0].expiredLeases || 0) : 0} \nDrafted Leases: ${clientLeases && clientLeases [0] ? Number(clientLeases[0].draftedLeases || 0) : 0}`}
                    >
                    {no_of_leases}
                    </div>
                }
            }
        },
        {
            name: "action",
            label: "Action",
            options : {
                customBodyRenderLite: (dataIndex) => (
                <Link to={{pathname:'/lease', search: clientData && clientData[dataIndex] && clientData[dataIndex]._id}} className="text-white">
                    <Button className="btn btn-sm bg-theme-color text-white" style={{marginTop: '10px'}}>
                    View Leases 
                    </Button>
                </Link>
                )
            }
          },
    ]

    const getAccountData = async () => {
        setLoading(true)
        try {
            const {data} = await axios.get(`${AppConfig.baseUrl}/customer/getDetails?id=${id}`,{
                headers: { token: localStorage.getItem("token") }
            });
            if(!data.error){
                setAccountData(data.custData || {})
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
            navigate("/accounts")
        }
        setLoading(false)
    }

    const getRevenueGraphData = async () => {
        try {
            const {data} = await axios.get(`${AppConfig.baseUrl}/customer/get_revenue?id=${accountData.customer_id._id}`,{
                headers: { token: localStorage.getItem("token") }
            });
            if(!data.error){
                const barData = []
                const label = data.months || months
                label.map((i,index) =>{
                    barData.push({amt:data.data[index],month:i})
                })
                setDataset(barData)
                let d = data.data
                let total = 0
                const revenue = Number(d.reduce((a,b) => a+b,total)).toFixed(2)
                setRevneue(revenue)
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
    }

    useEffect(() => {
        setId((location.search).replace('?', ''));
    }, [])

    useEffect(() => {
        if(id){
            getAccountData();
        }
        // eslint-disable-next-line
    },[id])

    const getUsers = async () => {
        setUsersData([])
        setLoadingUsers(true)
        try {
            const {data} = await axios.get(`${AppConfig.baseUrl}/customer/getUsers?id=${accountData.customer_id._id}`,{
                headers: { token: localStorage.getItem("token") }
            });
            if(!data.error){
                setUsersData(data.usersData)
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
            navigate("/accounts")
        }
        setLoadingUsers(false)
    }

    const getClients = async () => {
        setLoadingClients(true)
        try {
            const {data} = await axios.get(`${AppConfig.baseUrl}/customer/getClients?id=${accountData.customer_id._id}`,{
                headers: { token: localStorage.getItem("token") }
            });
            if(!data.error){
                setLeaseByClient(data.leaseDataByClient || [])
                setClientData(data.clientData)
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
            navigate("/accounts")
        }
        setLoadingClients(false)
    }

    useEffect(() => {
        if(Object.keys(accountData).length > 0){
            getUsers();
            getClients();
            getRevenueGraphData();
        }
    }, [accountData])

    useEffect(() => {
        setMiniCards([
            { title: "Total users", iconClass: "bx-user-circle", text: commaSeperatedNumberWithoutDecimal(usersData.length) },
            { title: "Total Clients", iconClass: "bxs-briefcase", text: commaSeperatedNumberWithoutDecimal(clientData.length) },
            { title: "Total revenue", iconClass: "bx-dollar", text: revenue },
        ])
    },[usersData, clientData,revenue])

    return (
        <Layout>
            <div className="page-content">
               <Helmet>
                    <title>LeaseVista | Account Details</title>
               </Helmet>
                <Container fluid>
                    <Breadcrumbs title="clientdetail" breadcrumbItem="Account Details" account_id={accountData && accountData.customer_id && accountData.customer_id._id} client={accountData && accountData.customer_id && accountData.customer_id.company} />
                    {
                        loading ? 
                            <div style={{textAlign: 'center'}}><Spinner color="primary" className="txt-theme-color" /></div>
                        : 
                        <>
                            <Row>
                                <Col>
                                    <Row>
                                        {map(miniCards, (card, key) => (
                                            <MiniCards
                                                title={card.title}
                                                text={card.text}
                                                iconClass={card.iconClass}
                                                key={"_card_" + key}
                                            />
                                        ))}
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl="4" lg="4">
                                    <Card className="overflow-hidden">
                                        <CardBody>
                                            <CardTitle className="mb-4">OVERVIEW</CardTitle>
                                            <Row className="mb-3">
                                                <Col xl="5" lg="5" xxl="5">
                                                    <h6>
                                                        Organization:
                                                    </h6>
                                                </Col>
                                                <Col xl="7" lg="7" xxl="7">
                                                    {accountData && accountData.customer_id && accountData.customer_id.company}
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row className="mb-3">
                                                <Col xl="5" lg="5" xxl="5">
                                                    <h6>
                                                        Contact:
                                                    </h6>
                                                </Col>
                                                <Col xl="7" lg="7" xxl="7">
                                                    {accountData && accountData.phone_number}
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row className="mb-3">
                                                <Col xl="5" lg="5" xxl="5">
                                                    <h6>
                                                        Email:
                                                    </h6>
                                                </Col>
                                                <Col xl="7" lg="7" xxl="7">
                                                    {accountData && accountData.email}
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row className="mb-3">
                                                <Col xl="5" lg="5" xxl="5">
                                                    <h6>
                                                        Phone:
                                                    </h6>
                                                </Col>
                                                <Col xl="7" lg="7" xxl="7">
                                                    {accountData && accountData.phone_number}
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row className="mb-3">
                                                <Col xl="5" lg="5" xxl="5">
                                                    <h6>
                                                        Start Date:
                                                    </h6>
                                                </Col>
                                                <Col xl="7" lg="7" xxl="7">
                                                    {accountData && accountData.customer_id && accountData.customer_id.start_date && moment.utc(accountData.customer_id.start_date).format("MM/DD/YYYY")}
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row className="mb-3">
                                                <Col xl="5" lg="5" xxl="5">
                                                    <h6>
                                                        Status:
                                                    </h6>
                                                </Col>
                                                <Col xl="7" lg="7" xxl="7">
                                                    <div>
                                                        {
                                                        accountData && accountData.customer_id && accountData.customer_id.status && 
                                                            <Badge
                                                                className={"font-size-12 badge-soft-" + accountData.customer_id.status === "trial" ? 'primary' : accountData.customer_id.status === "active" ? 'success' : 'warning'}
                                                                color={accountData.customer_id.status === "trial" ? 'primary' : accountData.customer_id.status === "active" ? 'success' : 'warning'}
                                                                pill
                                                                >
                                                                    {capitalize(accountData.customer_id.status)}
                                                            </Badge>
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xl="8" lg="8">
                                    <Card>
                                        <CardBody>
                                            <CardTitle>REVENUE</CardTitle>
                                            <BarChart datasets={datasets} height={340} width={800} xAxisDataKey={'month'} dataKey={'amt'} label={'$thousand'}/>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card>
                                        <CardBody>
                                            <ThemeProvider theme={createTheme({components: getMuiTheme()})}>
                                            <MUIDataTable
                                                title="CLIENTS"
                                                data={clientData}
                                                columns={columns}
                                                options={{ 
                                                rowsPerPage:10, 
                                                print:false, 
                                                download:false, 
                                                filter:false, 
                                                pagination : false,
                                                viewColumns:false,
                                                selectableRows:"none", 
                                                search:false, 
                                                rowsPerPageOptions:[], 
                                                responsive:'scroll',
                                                textLabels: {
                                                    body: {
                                                      noMatch: loadingClients ? <div><Spinner color="primary" className="text-lbusers" /></div> : "Sorry, no matching records found"
                                                    },
                                                    viewColumns: {
                                                    title: "",
                                                    },
                                                } 
                                                }}
                                            />
                                            </ThemeProvider>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Users usersData={usersData} loading={loadingUsers} getUsers={getUsers} id={id} accountData={accountData}/>
                                </Col>
                            </Row>
                        </>
                    }
                </Container>
            </div>
        </Layout>
    )
}


export default AccountDetails