import React, { useEffect, useState } from "react"
import { Row, Col, Card, Badge, CardBody,Input,TabPane,NavLink,NavItem,TabContent,Nav , Spinner} from "reactstrap"
import classnames from "classnames"
import {Helmet} from "react-helmet"
import DeleteAccount from "./deleteAccount";
import SoftDelete from "./softDelete";
import RestoreUser from "./restoreAccount";
import PermanentDelete from "./permanentDelete";
import Breadcrumbs from "../../components/Common/BreadcrumbAdd"
import "./datatables.scss"
import "../../assets/scss/manual/manual.scss"
import ButtonDropdownClient from "components/Common/ButtonDropdown";
import axios from "axios";
import AppConfig from "constants/config";
import toast from 'react-hot-toast';
import { capitalize } from "helpers/stringHelper";
import Layout from "components/VerticalLayout";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getMuiTheme } from 'helpers/mui_theme'

const Accounts = () => {
  const [accountsData, setAccountsData] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedOptions, setSelectedOption] = useState("");
  const [softDelete, setSoftDelete] = useState(false);
  const [restoreUser, setRestoreUser] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const [activeTab, setActiveTab] = useState("0");
  const [archieveData, setArchieveData] = useState([]);
  const [search, setSearch] = useState("");
  const [originalData, setOriginalData] = useState([]);
  const [search1, setSearch1] = useState("");
  const [originalData1, setOriginalData1] = useState([]);
  const columns = [
    {
      name: 'customer_id.company',
      label: 'Organization',
      options:{
        setCellProps: () => ({style: {minWidth: '100px', height: '70px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '100px', height: '40px'}}),
        customBodyRenderLite: (dataIndex, rowIndex) => (activeTab == '0' && accountsData && accountsData[dataIndex] && accountsData[dataIndex].customer_id && accountsData[dataIndex].customer_id.company ? accountsData[dataIndex].customer_id.company : 
        archieveData && archieveData[dataIndex] && archieveData[dataIndex].customer_id && archieveData[dataIndex].customer_id.company ? archieveData[dataIndex].customer_id.company : ""
        )
      }
    }, {
      name: 'contact_person',
      label: 'Contact name',
    }, {
      name: 'admin_email',
      label: 'Admin email',
      // searchable: false,
    }, {
      name: 'phone_number',
      label: 'Phone',
      // searchable: false
    }, {
      name: 'customer_id.status',
      label: 'Status',
      options:{
        sort : true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          if(activeTab == '0'){
          return <Badge
            color={accountsData && accountsData[dataIndex] && accountsData[dataIndex].customer_id && accountsData[dataIndex].customer_id.is_deleted === true ? 'secondary' : accountsData && accountsData[dataIndex] && accountsData[dataIndex].customer_id && accountsData[dataIndex].customer_id.status === "trial" ? 'primary' : accountsData && accountsData[dataIndex] && accountsData[dataIndex].customer_id && accountsData[dataIndex].customer_id.status === "active" ? 'success' : accountsData && accountsData[dataIndex] && accountsData[dataIndex].customer_id && accountsData[dataIndex].customer_id.status === "delinquent" ? 'danger':'warning'}
            pill
          >
            {capitalize(accountsData && accountsData[dataIndex] && accountsData[dataIndex].customer_id && accountsData[dataIndex].customer_id.status)}
          </Badge>
          }else{
            return <Badge
            color='secondary'
            pill
          >
            {'Archived'}
          </Badge>
          }
        },
      }
    }, {
      name: 'customer_id.is_online',
      label: 'Payment Mode',
      options:{
        sort : true,
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <div>
            {(activeTab == '0' && accountsData && accountsData[dataIndex] && accountsData[dataIndex].customer_id && accountsData[dataIndex].customer_id.is_online) || (archieveData && archieveData[dataIndex] && archieveData[dataIndex].customer_id && archieveData[dataIndex].customer_id.is_online) ? 'Auto' : 'Manual'}
          </div>
        ),
      }
    }, {
      name: 'customer_id.discount',
      label: 'Discount',
      options:{
        sort : true,
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <>
          {activeTab == '0' && accountsData && accountsData[dataIndex] && accountsData[dataIndex].customer_id && accountsData[dataIndex].customer_id.discount && accountsData[dataIndex].customer_id.discount !== 'D0' ? accountsData[dataIndex].customer_id.discount : 
          archieveData && archieveData[dataIndex] && archieveData[dataIndex].customer_id && archieveData[dataIndex].customer_id.discount && archieveData[dataIndex].customer_id.discount !== 'D0' ? archieveData[dataIndex].customer_id.discount : ""}
          </>
        )
      }
    }, {
      name: 'actions',
      label: 'Actions',
      options:{
        sort : false,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <ButtonDropdownClient setDeleteOpen={setDeleteOpen} user={activeTab == '0' && accountsData ? accountsData[dataIndex] : archieveData[dataIndex]} setSelectedUser={setSelectedUser} type="account" setSoftDelete={setSoftDelete} setRestoreUser={setRestoreUser} setDeleteUser={setDeleteUser}/>
          )
        },
      }
    }
  ];

  const toggleActiveTab = tab => {
      if (activeTab !== tab) {
          setActiveTab(tab);
      }
  };

  const getAccountsData = async(filter) => {
    setLoading(true);
    try {
        const {data} = await axios.get(`${AppConfig.baseUrl}/customer/listing?status=${filter.status || ""}`,{
          headers: { token: localStorage.getItem("token") }
        });
        if(!data.error){
          const data1 = data.custData.filter(v => v.contact_person.match(new RegExp(search,"i")) || v.customer_id.company.toString().match(new RegExp(search,"i")))
          setAccountsData(data1 || []);
          setOriginalData(data.custData || []);
        }
        else{
            throw new Error(data.title);
        }
    } catch (error) {
      toast.error(error.message || 'Something went wrong');
    }
    setLoading(false);
  }

  const getArchievData = async() => {
    setLoading(true);
    try {
        const {data} = await axios.get(`${AppConfig.baseUrl}/customer/listing?status=archive`,{
          headers: { token: localStorage.getItem("token") }
        });
        if(!data.error){
          setArchieveData(data.custData || []);
          setOriginalData1(data.custData || []);
        }
        else{
            throw new Error(data.title);
        }
    } catch (error) {
      toast.error(error.message || 'Something went wrong');
    }
    setLoading(false);
  }

  const handleChangeStatus = (e) => {
    setSelectedOption(e.target.value)
    getAccountsData({status : e.target.value})
  }

  useEffect(() => {
    getAccountsData({});
    getArchievData()
  },[])

  const toggleDelete = () => {
    setDeleteOpen(!deleteOpen);
  }

  const toggleSoftDelete = () => {
    setSoftDelete(!softDelete);
  }

  const toggleRestore = () => {
    setRestoreUser(!restoreUser);
  }

  const togglePermanentDelete = () => {
    setDeleteUser(!deleteUser);
  }

  const handleSearch = (value) => {
    const data = originalData.filter(v => v.contact_person.match(new RegExp(value,"i")) || v.customer_id.company.toString().match(new RegExp(value,"i")))
    setAccountsData(data)
  }

  const handleSearch1 = (value) => {
    const data = originalData1.filter(v => v.contact_person.match(new RegExp(value,"i")) || v.customer_id.company.toString().match(new RegExp(value,"i")))
    setArchieveData(data)
  }

  return (
    <Layout>
      <div className="page-content">
       <Helmet>
          <title>LeaseVista | Accounts</title>
       </Helmet>
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Accounts" />
          <Row>
              <Col>
                  <Card>
                      <CardBody>
                          <Nav tabs className="nav-tabs-custom nav-justified">
                              <NavItem>
                                  <NavLink
                                      style={{ cursor: "pointer" }}
                                      className={classnames({
                                          active: activeTab === "0",
                                      })}
                                      onClick={() => {
                                          toggleActiveTab("0");
                                      }}
                                  >
                                      <span className="d-none d-sm-block">ACTIVE</span>
                                  </NavLink>
                              </NavItem>
                              <NavItem>
                                  <NavLink
                                      style={{ cursor: "pointer" }}
                                      className={classnames({
                                          active: activeTab === "1",
                                      })}
                                      onClick={() => {
                                          toggleActiveTab("1");
                                      }}
                                  >
                                      <span className="d-none d-sm-block">ARCHIVED</span>
                                  </NavLink>
                              </NavItem>
                          </Nav>
                          <TabContent
                              activeTab={activeTab}
                              className="p-3 text-muted"
                          >
                              <TabPane tabId="0">
                                  <div className="modal-body">
                                    <ThemeProvider theme={createTheme({components: getMuiTheme()})}>
                                    <MUIDataTable
                                        title={
                                        <Row className="row">
                                          <Col md="6" className="pr-0">
                                            <div className="me-2 mb-3">
                                                <div className="position-relative sw">
                                                <Input
                                                    placeholder="Search"
                                                    type="text"
                                                    value={search}
                                                    onChange={(e) => {handleSearch(e.target.value);setSearch(e.target.value)}}
                                                />
                                                </div>
                                            </div>
                                          </Col>
                                          <Col md="6" className="dropdown-p">
                                            <div className="mb-3 ">
                                            <select
                                                value={selectedOptions === 'archive' ? '' :selectedOptions}
                                                onChange={handleChangeStatus}
                                                id="formrow-InputState"
                                                className="form-control"
                                              >
                                              <option name="status" value="">Status</option>
                                              <option name="trial" value="trial">Trial</option>
                                              <option name="active" value="active">Active</option>
                                              <option name="suspended" value="suspended">Suspended</option>
                                              <option name="delinquent" value="delinquent">Delinquent</option>
                                              </select>
                                            </div>
                                          </Col>
                                          </Row>
                                        }
                                        data={accountsData}
                                        columns={columns}
                                        options={{ 
                                        rowsPerPage:10, 
                                        print:false, 
                                        download:false, 
                                        filter:false, 
                                        selectableRows:"none", 
                                        search:false, 
                                        rowsPerPageOptions:[], 
                                        responsive:'scroll',
                                        textLabels: {
                                            body: {
                                              noMatch: loading ? <div><Spinner color="primary" className="text-lbusers" /></div> : "Sorry, no matching records found"
                                            },
                                            viewColumns: {
                                            title: "",
                                            },
                                        } 
                                        }}
                                    />
                                    </ThemeProvider>
                                  </div>
                              </TabPane>
                              <TabPane tabId="1">
                                  <div className="modal-body">
                                  <ThemeProvider theme={createTheme({components: getMuiTheme()})}>
                                    <MUIDataTable
                                        title={
                                        <Row className="row">
                                          <Col md="6" className="pr-0">                                            
                                            <div className="me-2 mb-3">
                                                <div className="position-relative sw">
                                                <Input
                                                    placeholder="Search"
                                                    type="text"
                                                    value={search1}
                                                    onChange={(e) => {handleSearch1(e.target.value);setSearch1(e.target.value)}}
                                                />
                                                </div>
                                            </div>
                                          </Col>
                                          <Col md="4">
                                          </Col>
                                         </Row>
                                        }
                                        data={archieveData}
                                        columns={columns}
                                        options={{ 
                                        rowsPerPage:10, 
                                        print:false, 
                                        download:false, 
                                        filter:false, 
                                        selectableRows:"none", 
                                        search:false, 
                                        rowsPerPageOptions:[], 
                                        responsive:'scroll',
                                        textLabels: {
                                            body: {
                                              noMatch: loading ? <div><Spinner color="primary" className="text-lbusers" /></div> : "Sorry, no matching records found"
                                            },
                                            viewColumns: {
                                            title: "",
                                            },
                                        } 
                                        }}
                                    />
                                    </ThemeProvider>
                                  </div>                                  
                              </TabPane>
                          </TabContent>
                      </CardBody>
                  </Card>
              </Col>
          </Row>
        </div>
      </div>
      <DeleteAccount deleteOpen={deleteOpen} toggleDelete={toggleDelete} setDeleteOpen={setDeleteOpen} user={selectedUser} getAccountsData={getAccountsData} setSelectedUser={setSelectedUser} />
      <SoftDelete softDelete={softDelete} toggleDelete={toggleSoftDelete} setSoftDelete={setSoftDelete} user={selectedUser} getAccountsData={getAccountsData} getArchievData={getArchievData} setSelectedUser={setSelectedUser} />
      <RestoreUser restoreUser={restoreUser} toggleRestore={toggleRestore} setRestoreUser={setRestoreUser} user={selectedUser} getAccountsData={getAccountsData} getArchievData={getArchievData} setSelectedUser={setSelectedUser} />
      <PermanentDelete deleteUser={deleteUser} togglePermanentDelete={togglePermanentDelete} setDeleteUser={setDeleteUser} user={selectedUser} getArchievData={getArchievData} setSelectedUser={setSelectedUser} />
    </Layout>
  )
}

export default Accounts
